/* Custom CSS */
.blog-img-large {
    min-height: 695px !important;
}

footer h3 {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: uppercase;
}

footer ul li {
    list-style: none;
}

.account-row {
    margin-bottom: 30px;
}

span.w-button.pageno {
    background-color: transparent;
    color: #444;
}

.brand-filter {
    display: none;
}

html, body {
    overflow-x: hidden;
}
/* Platfrom CSS */
.w-list-unstyled ul li {
    margin-left: 0;
    list-style: none !important;
}

.brand-filter {
    display: none;
}

footer .w-button, footer .button {
    color: hsla(0, 0%, 100%, .6) !important;
    border-color: hsla(0, 0%, 100%, .6) !important;
    background-color: transparent !important;
}

    footer .w-button:hover, footer .button:hover {
        color: #666 !important;
        background-color: white !important;
    }

.blogpost-caption div, .blogpost-hover div, .blogpost-text div {
    display: inline-block;
}

.blogpost-caption h1, .blogpost-caption h2, .blogpost-caption h3, .blogpost-caption h4 {
    margin-top: 0px;
    margin-bottom: 7px;
}

.newsletter-section h3 {
    margin-bottom: 0px;
    margin-top: 0px;
}

.w-container {
    max-width: 1170px;
    padding: 0px 20px;
}

.input-wrapper .login-btn {
    height: 40px;
}

footer h3, .banner-usp h3 {
    color: white;
}
.signup-radio-wrapper {
    height: 44px;
    margin-bottom: 10px;
}

.pf-signup-new-customer-wrapper {
    display: none;
}
.signup-info {
    position: absolute;
    right: 30px;
    top: 10px;
}

.sign-up-tooltip {
    background-color: #000 !important;
    color: #fff !important;
}

#AccountCodeTooltip {
    display: none;
}
@media (max-width: 991px) {
    div#paymentMethods > .w-col {
        width: 100%;
        margin-bottom: -20px;
    }

    .productwrapper {
        min-height: 454px;
    }

    .w-container {
        padding: 0px 10px;
    }
}

@media (max-width: 767px) {
    h1.productListingMainHeading, .breadcrumb {
        padding-left: 10px;
    }

    .product-detail-slider-arrow {
        color: #ddd;
    }

        .product-detail-slider-arrow:hover {
            color: #666;
        }

    .qtybasket.space {
        text-align: left;
    }

    .product-detail-description.p {
        margin-bottom: 30px;
    }

    .productwrapper {
        min-height: 510px;
    }

    .m-filter-wrapper {
        display: none;
        opacity: 0;
    }

    td.visible-xs.text-right {
        width: 13%;
    }

    .hidden-xs {
        display: none;
    }

    .visible-xs {
        display: table-cell;
    }

    .w-container.product-list-head {
        padding-bottom: 20px;
    }

    .product-detail-price {
        display: block;
        margin-top: 20px;
    }

    .variant-table {
        margin-bottom: 30px;
    }

    .product-detail-bottom-tabs {
        margin-top: 30px;
    }

    .product-detail-slider {
        display: block;
        height: auto;
        background-color: transparent;
        padding-bottom: 20px;
        display: block;
        opacity: 1;
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .filter-heading.mobile {
        display: block;
    }

    .w-row.hide {
        display: none;
        background-color: #fff;
    }

    .tab, .tab.w--current {
        width: 100%;
    }

    .tabs {
        display: none;
    }

    .breadcrumb {
        margin-top: 0px;
    }

    .tabcontent {
        border: none;
        padding-left: 10px;
        padding-right: 10px;
    }

    #node, #product-sort {
        margin-top: 0px;
    }

    .w-form.results-filter-wrapper {
        display: none;
    }

    .tabmenu {
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 479px) {
    .pagination {
        padding: 0px 10px;
    }
}
/*Blas CSS*/
.slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    display: inline-block;
    margin-top: 20px;
    margin-right: 4px;
    margin-left: 4px;
    padding: 12px 27px;
    border: 1px solid #fff;
    background-color: #e5005b;
    -webkit-transition: color .5s ease,background-color .5s ease,border .5s ease;
    transition: color .5s ease,background-color .5s ease,border .5s ease;
    color: #fff;
    font-size: 13px;
    line-height: 21px;
    font-weight: 400;
    text-shadow: none;
}

    .slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        border-color: transparent;
        background-color: #fff;
        color: #e5005b;
        text-decoration: none;
        text-shadow: none;
    }

.gridcat {
    background-position: 50% 50%;
    background-size: cover,125px;
    background-repeat: no-repeat,repeat;
}

    .gridcat > a {
        display: block;
        width: 100%;
        height: 100%;
    }

.button-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 26px;
    border: 1px solid #666;
    background-color: transparent;
    -webkit-transition: color .5s ease,background-color .5s ease,border .6s ease;
    transition: color .5s ease,background-color .5s ease,border .6s ease;
    font-family: 'Open Sans',sans-serif;
    color: #666;
    font-size: 12px;
    line-height: 21px;
    text-shadow: none;
    text-transform: uppercase;
}

    .button-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        background-color: #666;
        color: #fff;
        text-decoration: none;
    }

.AboutList .section:nth-child(2n+1) .w-col.w-col-6 {
    float: right;
}

.blogcategories li {
    list-style: none;
}

.tag-container {
    margin-left: 20px;
}

.pf-favs-wrapper .qty-btn {
    font-size: 14px !important;
}

.ui-autocomplete {
    z-index: 99999 !important;
}

#myCarousel-1-1 .mbss-editmode {
    position: absolute;
}

#myCarousel-1-1 ul.mbss-textArea-btns {
    top: 45%;
    left: 6%;
}

#myCarousel-1-1 ul.redactor-toolbar {
    left: 3% !important;
}

.brandsection .editImageX {
    top: 0 !important;
    left: 100px !important;
}

.flex-child.extra-small .mbss-editmode {
    position: absolute;
}
/*IE Explorer Fix*/
.banner-container.w-container {
    max-width: inherit;
}

@media screen and (max-width: 479px) {
    .w-col-tiny-6 {
        width: 100%;
    }

    .img-overlay, .img-overlay2 {
        height: 300px;
    }
}

@media screen and (max-width: 991px) {
    #pf-product-details-page .w-col-stack {
        width: unset;
        left: auto;
        right: auto;
    }

    #pf-related-recent-products-wrapper .productwrapper {
        min-height: unset;
    }
}

@media (max-width: 767px) {
    .center-flex {
        position: absolute;
    }

    #pf-related-recent-products-wrapper .tab, .tab.w--current {
        width: 100%;
    }
}

#cookie-bar {
    z-index: 9999;
}


@font-face {
    font-family: "acta-display";
    src: url("https://use.typekit.net/af/7d10bb/000000000000000000014175/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/7d10bb/000000000000000000014175/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/7d10bb/000000000000000000014175/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "acta-display";
    src: url("https://use.typekit.net/af/868e7c/000000000000000000014176/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/868e7c/000000000000000000014176/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/868e7c/000000000000000000014176/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: "acta-display";
    src: url("https://use.typekit.net/af/88b164/000000000000000000014171/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/88b164/000000000000000000014171/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/88b164/000000000000000000014171/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "acta-display";
    src: url("https://use.typekit.net/af/804648/000000000000000000014172/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/804648/000000000000000000014172/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/804648/000000000000000000014172/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: "acta-display";
    src: url("https://use.typekit.net/af/d05c50/00000000000000000001416f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/d05c50/00000000000000000001416f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/d05c50/00000000000000000001416f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "acta-display";
    src: url("https://use.typekit.net/af/63a384/000000000000000000014170/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/63a384/000000000000000000014170/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/63a384/000000000000000000014170/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
    font-style: italic;
    font-weight: 300;
}

#pf-product-details-page .pf-product-image-thumb {
    width: initial;
}

#scrollUp {
    right: 15px;
    bottom: 15px;
    display: block;
    padding-top: 11px;
    background-color: #777;
    -webkit-transition: color .5s ease,background-color .5s ease;
    transition: color .5s ease,background-color .5s ease;
    text-align: center;
    border-radius: 100%;
    opacity: 1;
}

.filter-count, .pagination-text.pf-paging-count, .telfooter, .shopppingrow .delivery-options {
    font-family: sans-serif;
}
/*fixes*/
.lg-outer, .lg-backdrop {
    z-index: 999999 !important;
}

.pf-product-main-image-wrapper:hover {
    cursor: zoom-in;
}

.product-code {
    padding-left: 0 !important;
}

#pf-product-details-page, #pf-product-list-page, #pf-product-listing {
    padding-top: 0;
    padding-bottom: 0;
}

.breadcrumb {
    margin-bottom: 30px;
}

.pf-filter-item {
    padding: 5px 12px;
}

.megaheading.w--current {
    color: black;
    font-weight: bold;
}

@media screen and (max-width: 450px) {
    .cart-table tr td:nth-child(1) {
        width: 40%;
        padding: 1px;
    }
}

@media screen and (max-width: 350px) {
    #checkout-cart .pf-qty-btn {
        width: 17px;
    }

    #checkout-cart .pf-qty-select {
        width: 28px;
    }
}

.pf-favs-wrapper .qty-btn {
    font-size: 17px !important;
    font-weight: bold;
}

@media (max-width: 767px) {
    .m-filter-wrapper {
        display: block;
        opacity: 1;
    }

    .pf-tab-header {
        width: 100% !important;
        text-align: center;
    }

    .tag-container {
        margin-left: 0px;
    }

    .category-blog {
        padding: 0px;
    }

    .blogcategories .categorylink {
        padding-right: 4px;
    }

    .pf-sales-account-page .AdminTbl.table {
        width: 100% !important;
    }

    .center-flex._4, .center-flex._3 {
        top: unset;
    }

    .blog-img-large {
        min-height: 210px !important;
    }

    .AboutList img {
        height: 100%;
        width: 100%;
    }

    .tabmenu.pf-tabs-top {
        margin-bottom: 20px;
    }

    #pf-product-listing {
        padding-top: 0px;
    }

    #pf-product-list-page .product-list-head {
        padding-bottom: 0px;
    }
}

@media (max-width: 991px) {
    #pf-product-list-page .productwrapper {
        min-height: 415px;
    }
}

.list-view-add-cart-wrapper .pf-qty-btn-minus {
    /*margin-right:-4px;*/
}

#pf-clear-filters-btn:hover {
    color: white;
    text-decoration: none;
}

.pf-product-images-slider-arrow {
    background-color: transparent;
    opacity: unset;
}

.pf-terms-wrapper a {
    color: #aaa;
}

#opc_button {
    margin: 0;
    border-color: transparent;
    font-weight: 400;
    position: relative;
    font-size: 20px;
    font-family: inherit;
    padding: 5px 12px;
    overflow: hidden;
    border-width: 0;
    border-radius: 4px;
    background: #fff;
    color: rgba(255,255,255,.4);
    text-align: center;
    border: 1px solid #666;
    color: #666;
    padding: 5px 10px;
    font-size: 15px;
    font-family: inherit;
}

    #opc_button:hover {
        color: #fff;
        background: #666;
    }

.pf-product-main-image-wrapper img {
    width: 100%;
}

.pf-product-details-variant-table .pf-add-to-cart-btn {
    margin-left: -4px;
}

.banner.news {
    background-size: cover;
    background-position: 50% 50%;
}

.pf-my-orders-table td {
    padding: 5px 3px;
}

.lobibox-notify-wrapper, .lobibox-notify-wrapper-large {
    z-index: 9001 !important;
}

.pf-product-list-item:hover, .pf-recent-related-product-item:hover {
    box-shadow: 0 6px 20px 4px rgba(26,26,26,.25);
    /*-webkit-transform: translate(0,-5px);
        -ms-transform: translate(0,-5px);
        transform: translate(0,-5px);*/
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
}

#top-cart-holder {
    font-family: 'Open Sans', sans-serif;
}

.banner-text-large, h1, h2, h3, h4 {
    font-family: 'Open Sans',sans-serif;
}
@media (min-width:1024px) {
    .social-icon-wrapper.social-top.st.shrink-social-media {
        top: 25px;
    }
}
@media (max-width:1024px) {

    .brandsection {
        background-attachment: scroll !important;
    }

    .shrink-social-media {
        top: 27px;
    }
}

.cart-qty {
    white-space: nowrap;
}

.img-overlay2 {
    height: auto;
}

.blogpost-wrapper .blog-img-medium {
    min-height: unset;
}

.news ._1.blog-img-medium.w-inline-block {
    padding-bottom: 0;
}

.cart-stock {
    color: #cc0000;
}

    .cart-stock .cart-label {
        display: none;
    }

.custom-stock-message {
    color: #cc0000;
}

.custom-stock-message-label {
    display: none;
}

.delivery-calendar-picker {
    margin-top: 15px;
}

@media (max-width:1200px) {
    .blogPostStackIpad {
        width: 100% !important;
    }
}

#discount-input-tbx {
    height: 40px;
}

.pf-recent-related-product-item .productwrapper {
    box-shadow: none !important;
}

.pf-filter-wrapper .pf-filter-dropdown-btn, .pf-filter-wrapper .pf-spec-filter-dropdown-btn {
    margin: 6px 20px auto auto;
}
/*Mint*/
.blog-img-large {
    min-height: 695px !important;
}

footer h3 {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: uppercase;
}

footer ul li {
    list-style: none;
}

.account-row {
    margin-bottom: 30px;
}

span.w-button.pageno {
    background-color: transparent;
    color: #444;
}

.brand-filter {
    display: none;
}

html, body {
    overflow-x: hidden;
}
/* Platfrom CSS */
.w-list-unstyled ul li {
    margin-left: 0;
    list-style: none !important;
}

.brand-filter {
    display: none;
}

footer .w-button, footer .button {
    color: hsla(0, 0%, 100%, .6) !important;
    border-color: hsla(0, 0%, 100%, .6) !important;
    background-color: transparent !important;
}

    footer .w-button:hover, footer .button:hover {
        color: #666 !important;
        background-color: white !important;
    }

.blogpost-caption div, .blogpost-hover div, .blogpost-text div {
    display: inline-block;
}

.blogpost-caption h1, .blogpost-caption h2, .blogpost-caption h3, .blogpost-caption h4 {
    margin-top: 0px;
    margin-bottom: 7px;
}

.newsletter-section h3 {
    margin-bottom: 0px;
    margin-top: 0px;
}

.w-container {
    max-width: 1170px;
    padding: 0px 20px;
}

.input-wrapper .login-btn {
    height: 40px;
}

footer h3, .banner-usp h3 {
    color: white;
}

.searchform-wrapper.search-tablet {
    display: none;
}

.brand.w-nav-brand {
    padding-top: 10px;
    padding-bottom: 10px;
    float: left;
}

.mid-footer h3 {
    color: #666;
    font-family: 'Open Sans',sans-serif;
}

.mid-footer a, .bottomfooter a {
    color: black;
}

    .mid-footer a:hover, .bottomfooter a:hover {
        color: #666;
        text-decoration: none;
    }

.bottomfooter p {
    margin-bottom: 0px;
}

#front-end {
    padding-top: 160px;
}

.mid-footer {
    border-top: solid 1px #ddd;
}

.slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    display: inline-block;
    margin-top: 20px;
    margin-right: 4px;
    margin-left: 4px;
    padding: 12px 27px;
    border: 1px solid #fff;
    background-color: #54a244;
    -webkit-transition: color .5s ease,background-color .5s ease,border .5s ease;
    transition: color .5s ease,background-color .5s ease,border .5s ease;
    color: #fff;
    font-size: 13px;
    line-height: 21px;
    font-weight: 400;
    text-shadow: none;
}
.errormessage p {
    font-weight: bold;
    color: red;
}
    .slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        border-color: transparent;
        background-color: #fff;
        color: #54a244;
        text-decoration: none;
        text-shadow: none;
    }

.ctabtn-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 26px;
    -webkit-transition: color .5s ease,background-color .5s ease,border .6s ease;
    transition: color .5s ease,background-color .5s ease,border .6s ease;
    font-family: 'Open Sans',sans-serif;
    font-size: 12px;
    line-height: 21px;
    border: 1px solid #fff;
    background-color: #54a244;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    box-shadow: none;
    text-shadow: none;
    letter-spacing: 1px;
}

    .ctabtn-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        border-color: transparent;
        background-color: #fff;
        color: #54a244;
        text-shadow: none;
    }

.flex-child {
    width: 35%;
}

    .flex-child.small {
        width: 60.25%;
    }

    .flex-child.full {
        width: 97.5%;
    }

.flex-child.half {
    width: 47.5%;
}

.img-overlay {
    height: 320px;
}

.flex-child.full .img-overlay {
    height: 360px;
}

.product-name, body {
    font-family: 'Open Sans',sans-serif;
}

.blogpost-category-link, .slider-container a {
    font-family: 'Open Sans',sans-serif;
}

.shrink {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.slider-nav {
    background-color: transparent;
}

.nav-link.mid.w-nav-link.company-name {
    max-width: 1170px;
    padding: 0px 10px;
    float: left;
    top: -30px;
}
.breadcrumb {
    margin-bottom: 15px;
}

.sub-cats-featured {
    width: 100%;
    float: left;
    border-bottom: 3px solid #ddd;
    padding-bottom: 30px;
    margin-bottom: 25px;
}

@media (max-width: 991px) {
    #front-end {
        padding-top: 245px;
    }
    .slider,.w-slider-mask {
    height:60vh;
    }

    div#paymentMethods > .w-col {
        width: 100%;
        margin-bottom: -20px;
    }

    #pf-product-list-page .col-subcat .productwrapper {
        min-height: 220px;
    }

    .productwrapper {
        min-height: 454px;
    }

    .w-container {
        padding: 0px 10px;
    }

    .shrink {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .header {
        font-size: 15px;
    }

    .text-block-3 {
        padding-top: 0px;
    }
}

@media (max-width: 1300px) and (min-width: 768px) {
    .bottomfooter {
        padding: 25px 50px;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    #company-name-header {
        margin-left: 3px;
        text-align: left;
    }

    .nav-link.mid {
        padding-top: 0;
        padding-bottom: 4px;
        text-align: left;
    }
}

@media (max-width: 767px) {
    .text-block-3 {
    position: relative;
    display: block;
    margin-top: 6px;
    padding-top: 0;
    font-size: 13px;
}
    .div-block-6 {
    position: static;
    margin-bottom: 4px;
    padding-top: 0;
}
    .nav-menu.top {
    position: static;
    display: block;
    margin-top: 0;
    padding-right: 10px;
    padding-left: 10px;
    opacity: 1;
    height: 80px;
}
    .brand {
    max-width: 120px;
    padding-left: 0;
    text-align:center;
    }
    .company-name .text-block-3 {
        min-height: 20px;
    }
    .flex-child,.flex-child.half,.flex-child.small,.flex-child.full {
    width: auto;
}
    #pf-product-details-page, #pf-product-list-page, #pf-product-listing {
    margin-top:30px;
    }
    #pf-my-account-page .pf-tab-header.tab {
        width: 100%;
    }
    .pf-addresses-wrapper .w-col-6 {
        width: 100%;
    }
    .w-nav-overlay, #top-cart-holder,.topheader{
    overflow-y: auto;
}
    #front-end {
        padding-top: 165px;
    }

    .w-nav[data-collapse=small] .w-dropdown,.w-nav[data-collapse=small] .w-dropdown-toggle {
        display: block;
    }

    .nav-link.w--nav-link-open {
        padding-top: 12px;
        padding-bottom: 12px;
        color: hsla(0,0%,100%,.6);
    }

    .nav-link.nav-dropdown.w-dropdown-toggle, .nav-link.nav-dropdown.w--open {
        padding-top: 12px;
        padding-bottom: 12px;
        color: #fff;
        width: 100%;
    }

    .mega-col.w-col-small-6 {
        width: 50%;
    }

    .pf-page-wrapper {
        margin-top: 70px;
    }

    .mega-list.w--open {
        max-width: 668px;
        overflow: hidden;
        margin-left: 0;
        width: 100%;
        position: static;
    }

    .nav-menu {
        background-color: rgba(68,68,68,.9);
    }

    .nav-link.mid.w-nav-link .hidemobile {
        display: none;
    }

    .nav, .nav-link.nav-dropdown {
        text-align: left;
    }

    .nav {
        position: relative;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .w-nav[data-collapse=small] .w-nav-button {
        display: block;
    }

    .w-nav[data-collapse=small] .w-nav-menu {
        display: none;
    }

    .menu-button {
        display: inline-block;
        margin-top: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 10px;
        float: right;
        border: 1px solid #ddd;
        color: #aaa;
    }

    .nav-link.mid {
        display: inline-block;
        padding: 35px 10px;
    }

    .shrink {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }

    h1.productListingMainHeading, .breadcrumb {
        padding-left: 10px;
    }

    .product-detail-slider-arrow {
        color: #ddd;
    }

        .product-detail-slider-arrow:hover {
            color: #666;
        }

    .qtybasket.space {
        text-align: left;
    }

    .product-detail-description.p {
        margin-bottom: 30px;
    }

    .productwrapper {
        min-height: 510px;
    }

    .m-filter-wrapper {
        display: none;
        opacity: 0;
    }

    td.visible-xs.text-right {
        width: 13%;
    }

    .hidden-xs {
        display: none;
    }

    .visible-xs {
        display: table-cell;
    }

    .w-container.product-list-head {
        padding-bottom: 20px;
    }

    .product-detail-price {
        display: block;
        margin-top: 20px;
    }

    .variant-table {
        margin-bottom: 30px;
    }

    .product-detail-bottom-tabs {
        margin-top: 30px;
    }

    .product-detail-slider {
        display: block;
        height: auto;
        background-color: transparent;
        padding-bottom: 20px;
        display: block;
        opacity: 1;
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .filter-heading.mobile {
        display: block;
    }

    .w-row.hide {
        display: none;
        background-color: #fff;
    }

    .tab, .tab.w--current {
        width: 100%;
    }

    .tabs {
        display: none;
    }

    .breadcrumb {
        margin-top: 0px;
    }

    .tabcontent {
        border: none;
        padding-left: 10px;
        padding-right: 10px;
    }

    #node, #product-sort {
        margin-top: 0px;
    }

    .w-form.results-filter-wrapper {
        display: none;
    }

    .tabmenu {
        margin-bottom: 0px;
    }

    .searchform-wrapper {
        display: none;
    }

        .searchform-wrapper.search-tablet {
            position: relative;
            display: inline-block;
            width: 60%;
            margin-top: 13px;
            margin-bottom: 13px;
            margin-left: 10px;
            float: left;
            clear: none;
        }

            .searchform-wrapper.search-tablet.shrink {
                margin-top: 11px;
                margin-bottom: 10px;
            }

    .hidemobile {
        display: none;
    }
    /*.nav-link.mid {
    display: block;
    padding-top: 0;
    padding-bottom: 4px;
    text-align: left;
}*/


    #company-name-header {
        margin-left: 10px;
        text-align: left;
    }

    .sub-cats-featured {
        padding-bottom: 50px;
    }
}

@media screen and (max-width: 479px) {
    .social-icon-wrapper.social-top.st.shrink-social-media {
padding-top:44px!important;
}
    .remodal-ecoband .w-col-6 {
        width: 100%;
    }
    .nav-account.w-nav-link {
        position:absolute;
    display:block!important;
    white-space:nowrap;
    text-overflow:ellipsis;
    top:25px!important;
    }
    .searchform-wrapper.search-tablet,.menu-button.w-nav-button {
    margin-bottom:10px;
    margin-top:10px;
    }
    .company-name {
    margin-bottom:0px;
    padding-bottom:0px;
    margin-right:5px;
    text-align:right;
    }
    .w-tab-link.w--current.w-inline-block.tab.pf-tab-header {
    border-bottom:1px solid #ddd;
    }
    #edit-details-btn,#cancel-details-btn,#save-address-btn {
    margin-left:10px;
    }
    #orders-tab-content {
    overflow-x:scroll;
    }
    .social-icon-wrapper.social-top {
    padding-bottom:0px;
    padding-right:10px!important;
    float:right!important;
    padding-top:40px!important;
    }
    .social-top .social-icon {
        width: 25px;
        height:20px;
    }
    .brand {
        left: 21%;
    }
    .top-right {
        padding-top: 17px!important;
        padding-bottom: 0px;
        float:right!important;
        display:block;
    }
    #front-end {
    padding-top: 145px;
}
    .pf-page-wrapper {
    margin-top:30px;
    }
    .checkoutsection {
        padding: 100px 10px;
    }
    .quantity.qty-tbx.pf-qty-select {
        font-size: 16px;
    }

    #company-name-header {
        margin-left: 0px;
        text-align: center;
    }

    .shrink {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .pagination {
        padding: 0px 10px;
    }

    .nav-link.mid {
        display: inline-block;
        margin-top: 0;
        padding: 0 5px 8px;
        font-size: 12px;
        top:27px;
    }

    .social-icon-wrapper.social-top.st {
        float: none;
    }

    .company-name .text-block-3 {
        text-align: center;
        min-height:20px;
    }

    .social-link.social-icons-top {
        padding-top: 0px;
    }
}

.bx-controls {
    margin-bottom: -40px !important;
    padding-left: 10px;
}

.bx-controls-auto {
    right: 10px !important;
}

.bx-pager-link.active {
    background-color: white !important;
}

.bx-controls-auto {
    padding-right: 10px;
    padding-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.70);
    padding-left: 10px;
}

.bx-controls-auto {
    width: 55px !important;
}

.shrink-logo {
    width: 140px;
}

.shrink-nav-links {
    padding-top: 7px;
    padding-bottom: 7px;
}

.social-link.social-icons-top {
    background-color: transparent;
}

.topheader {
    background-color: white;
}

#discount-btn {
    top: -3px;
}

.nav-link.w--current {
    font-family: 'Open Sans',sans-serif;
}

.social-top {
    padding-right: 0px !important;
}

    .social-top .social-icons-top {
        margin-right: 0px;
    }

.quantity.qty-tbx.pf-qty-select {
    color: #666;
}

input:disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 1);
    -webkit-opacity: 1;
}
.lg-backdrop.in, .lg-outer {
z-index:9999!important;
}
.brand.w-nav-brand.w--current {
position:absolute;
}
.w-slider-mask {
    height: 70vh;
}
.wrapping-option-available-product-list {
    background-color: #666;
    color: #fff;
    font-size: 12px;
    padding: 5px;
    margin: 5px 0;
    line-height: 16px;
}

.wrapping-option-available-product-view {
    color: #fff;
    background-color: #666;
    padding: 10px;
    display: inline-block;
    margin-top: 10px;
    line-height: 16px;
    font-size: 13px;
}

.ecoband-column {
    border-left: 1px solid #666;
}
.remodal-ecoband h4, .remodal-ecoband p {
    text-align: initial;
    color: #5a5d61;
}
h4.ecoband-title {
    color: #75b558;
}

.ecoband-popup-title {
    background-color: #00a66f;
    color: #fff;
    padding: 10px;
}

.remodal.remodal-ecoband {
    max-width: 800px;
    padding: 25px;
}

.remodal-ecoband p {
    font-weight:400;
    font-size:17px;
}
.remodal-ecoband h4 {
    margin-top: 0;
    text-transform: none;
}

.remodal-ecoband h3 {
    font-size: 25px;
    line-height: 33px;
}

.remodal-ecoband .ecoband-content-column {
    padding-left: 10px;
}
@media (min-width: 1750px) {
    .w-slider-mask {
    height: 55vh;
}
    }
@media (max-width: 1750px) {
    .flex-child .img-overlay {
        height: 295px;
    }

    .flex-child.full .img-overlay {
        height:320px;
    }

}

@media (max-width: 1600px) {
    .flex-child .img-overlay {
        height: 275px;
    }

    .flex-child.full .img-overlay {
        height:300px;
    }
}
@media (max-width: 1550px) {
    .flex-child .img-overlay {
        height: 255px;
    }
}
@media (max-width: 1450px) {
    .flex-child .img-overlay {
        height: 235px;
    }
    .flex-child.full .img-overlay {
        height:275px;
    }
}
@media (max-width: 1300px) {
    .flex-child .img-overlay {
        height: 210px;
    }
    .flex-child.full .img-overlay {
        height:250px;
    }
}
@media (max-width: 1230px) {
    .flex-child .img-overlay {
        height: 210px;
    }
    .flex-child.full .img-overlay {
        height:230px;
    }
}
@media (max-width: 1230px) {
    .flex-child .img-overlay {
        height: 190px;
    }
    .flex-child.full .img-overlay {
        height:210px;
    }
}
@media (max-width: 1020px) {
    .flex-child .img-overlay {
        height: 175px;
    }
    .flex-child.full .img-overlay {
        height:195px;
    }
}
@media (max-width: 1024px) {
    .w-slider-mask {
        height: 500px;
    }
}
@media (max-width: 950px) {
    .flex-child .img-overlay {
        height: 155px;
    }
    .flex-child.full .img-overlay {
        height:170px;
    }
}
@media (max-width: 835px) {
    .flex-child .img-overlay {
        height: 135px;
    }
    .flex-child.full .img-overlay {
        height:150px;
    }
    .slider, .w-slider-mask {
        height: 55vh;
    }
}
@media (max-width: 767px) {
    .flex-child .img-overlay {
        height: 200px;
    }
        .flex-child.half .img-overlay {
        height: 240px;
    }
    .flex-child.full .img-overlay {
        height:130px;
    }
    .remodal-ecoband .w-col-6 {
        width: 50%;
    }
    .wrapping-option-available-product-list {
        padding: 10px;
        width: 100%;
    }
}
@media (max-width: 690px) {
    .slider, .w-slider-mask {
        height: 45vh;
    }
    .flex-child .img-overlay {
        height: 185px;
    }
        .flex-child.half .img-overlay {
        height: 210px;
    }
    .flex-child.full .img-overlay {
        height:120px;
    }
}
@media (max-width: 600px) {
    .slider, .w-slider-mask {
        height: 40vh;
    }
}
@media screen and (max-width: 500px) {
    .remodal-ecoband .w-col-6 {
        width: 100%;
    }
}
@media (max-width: 400px) {
    .slider, .w-slider-mask {
        height: 250px !important;
        min-height: 150px;
    }
    .slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    padding:8px 26px;
    font-size:10px;
    }
}

@media (max-width: 650px) {
    .flex-child .img-overlay {
        height: 165px;
    }
        .flex-child.half .img-overlay {
        height: 190px;
    }
    .flex-child.full .img-overlay {
        height:110px;
    }
}
@media (max-width: 575px) {
    .flex-child .img-overlay {
        height: 145px;
    }
        .flex-child.half .img-overlay {
        height: 175px;
    }
    .flex-child.full .img-overlay {
        height:90px;
    }
}
@media (max-width: 500px) {
    .flex-child .img-overlay {
        height: 145px;
    }
        .flex-child.small .img-overlay {
        height: 125px;
    }
        .flex-child.half .img-overlay {
        height: 155px;
    }
    .flex-child.full .img-overlay {
        height:80px;
    }
}
@media (max-width: 450px) {
    .flex-child .img-overlay {
        height: 145px;
    }
        .flex-child.small .img-overlay {
        height: 105px;
    }
        .flex-child.half .img-overlay {
        height: 125px;
    }
    .flex-child.full .img-overlay {
        height:70px;
    }
}
@media (max-width: 375px) {
    .flex-child .img-overlay {
        height: 125px;
    }
        .flex-child.small .img-overlay {
        height: 95px;
    }
        .flex-child.half .img-overlay {
        height: 115px;
    }
    .flex-child.full .img-overlay {
        height:60px;
    }
}
@media (max-width: 345px) {
    .flex-child .img-overlay {
        height: 105px;
    }
        .flex-child.small .img-overlay {
        height: 75px;
    }
        .flex-child.half .img-overlay {
        height: 95px;
    }
    .flex-child.full .img-overlay {
        height:45px;
    }
}
@media (max-width: 350px) {
    .slider, .w-slider-mask {
        height: 200px !important;
        min-height: 150px;
    }
    .slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    padding:8px 26px;
    font-size:10px;
    }
}
.bx-wrapper .bx-controls-direction a {
z-index:1000!important;
}

.w-slider.slider {
    height: auto;
}
.w-slider.slider .bx-wrapper {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-top: none;
    border-left: none;
    border-right: none;
}

.nav-link.nav-dropdown {
    font-family: 'Open Sans',sans-serif;
}
.blog.section .blogpost-wrapper ._1.blog-img-large {
background-repeat:no-repeat;
background-size:contain;
}
.megaheading.w-dropdown-link {
text-align:center;
}
.w-slider-mask.AdminBanner {
    max-height:600px;
}
.w-slider-arrow-left, .w-slider-arrow-right, .w-slider-arrow-right .arrow,.w-slider-arrow-left .arrow {
color:black;
}
@media (max-width: 430px) {
    .slider-nav {
        bottom: 30px;
        padding-bottom: 1px;
    }
    .slider-container {
    padding: 5px 20px 20px;
}
    .slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    padding: 2px 26px;
    font-size: 10px;
    margin-top: 0px;
}
}